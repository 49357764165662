<template>
  <div class="BackgroundObject" :type="$route.name">
    <video src="https://img.dwhs.cn/cdn/video/datascreen-bg-ys.mp4" style="object-fit: cover; width: 100%; height: 100%" autoplay loop ref="video" muted preload v-show="$route.meta.showVideo"></video>
  </div>
</template>

<script>
export default {
  name: "BackgroundObject",
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
.BackgroundObject {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/images/bg1@2x.png")  no-repeat;
  background-size: 100% 100%;
  &[type='Map']{
    background: url("../assets/images/bg@2x.png")  no-repeat;
    background-size: 100% 100%;
  }
  &[type='Affects']{
    background: url("../assets/images/affects/affect_bg.png")  no-repeat;
    background-size: 100% 100%;
  }
  &[type='Student']{
    background: url("../assets/images/teacher/teacher_bg.png") no-repeat;
    background-size: 100% 100%;
  }
  &[type='Teacher']{
    background: url("../assets/images/teacher/teacher_bg.png") no-repeat;
    background-size: 100% 100%;
  }
  z-index: 1;
}
</style>

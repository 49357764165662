<template>
  <div style="width: 100%; height: 100%">
    <background-object/>
    <div class="home">
      <router-view/>
    </div>
  </div>
</template>

<script>

import BackgroundObject from "../components/BackgroundObject";
export default {
  name: 'Home',
  components: {BackgroundObject},
}
</script>

<style lang="scss" scoped>
.home{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    redirect: '/map'
  },
  {
    path: '/index',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/map',
        name: 'Map',
        component: ()=>import('../views/Map.vue'),
      },
      {
        path: '/know',
        name: 'Know',
        component: ()=> import('../views/Know.vue'),
      },
      {
        path: '/subjects',
        name: 'Subjects',
        component: ()=> import('../views/Subjects.vue'),
        meta: {
          showVideo: true,
        }
      },
      {
        path: '/rd',
        name: 'RDCenter',
        component: ()=> import('../views/RDCenter.vue'),
        meta: {
          showVideo: true,
        }
      },
      {
        path: '/affects',
        name: 'Affects',
        component: ()=> import('../views/Affects.vue'),
        meta: {
          showVideo: true,
        }
      },
      {
        path: '/student',
        name: 'Student',
        component: ()=> import('../views/Student.vue'),
        meta: {
          showVideo: true,
        }
      },
      {
        path: '/teacher',
        name: 'Teacher',
        component: ()=> import('../views/Teacher.vue'),
        meta: {
          showVideo: true,
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/getcode',
    name: 'LoginScanCode',
    component: () => import('../views/LoginScanCode.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
